import { useEffect, useMemo, useState } from "react";
import type { ReactNode, MouseEvent } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  Typography,
  tableBodyClasses,
  tableCellClasses,
  tableContainerClasses,
  tableRowClasses,
} from "@mui/material";
import type { Perimetre, Personne, Mandat, Commune } from "models";
import { communesService, elusService } from "services";
import PerimetreDeleguesSdeaSkeleton from "./PerimetreDeleguesSdeaSkeleton";
import PerimetreDeleguesSdeaRow from "./PerimetreDeleguesSdeaRow";
import Fonctions from "constants/Fonctions";
import { elusUtil, excelUtil } from "utils";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import { Authorization } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";
import { enqueueSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import LoadingButton from "components/LoadingButton";
import PerimetreDeleguesSdeaHeader from "./PerimetreDeleguesSdeaHeader";
import type { ColumnKeyName, Order, PerimetreDelegueRow } from "./PerimetreDeleguesSdeaHeader";

type ComparatorFunc<Key extends keyof ColumnKeyName> = (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number;

function ascendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
    return (a[orderBy] as string).localeCompare(b[orderBy] as string);
  }

  if (typeof a[orderBy] === "number" && typeof b[orderBy] === "number") {
    return (a[orderBy] as number) - (b[orderBy] as number);
  }

  return 0;
}

function getComparator<K extends keyof ColumnKeyName>(order: Order, orderBy: K): ComparatorFunc<K> {
  return order === "desc"
    ? (a, b) => -ascendingComparator(a, b, orderBy)
    : (a, b) => ascendingComparator(a, b, orderBy);
}

interface PerimetreDeleguesSdeaProps {
  perimetre: Perimetre;
}

function PerimetreDeleguesSdea({ perimetre }: Readonly<PerimetreDeleguesSdeaProps>): ReactNode {
  const [elus, setElus] = useState<Array<Personne>>([]);
  const [communes, setCommunes] = useState<Array<Commune>>([]);

  const [tableElusMandatCommune, setTableElusMandatCommune] = useState<Array<PerimetreDelegueRow>>(
    []
  );
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof ColumnKeyName>("mandatsWeight");

  const { catchErrors: catchDatasErrors, isLoading: isDatasLoading } = useErrorHandler();
  const { catchErrors: catchExcelErrors, isLoading: isExcelLoading } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  function handleRequestSort(_: MouseEvent<unknown>, p: keyof ColumnKeyName): void {
    const isAsc = orderBy === p && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(p);
  }

  const tableSort = useMemo<Array<PerimetreDelegueRow>>(
    () => [...tableElusMandatCommune].sort(getComparator(order, orderBy)),
    [order, orderBy, tableElusMandatCommune]
  );

  useEffect(() => {
    async function getElus(): Promise<void> {
      setTableElusMandatCommune([]);
      setElus([]);
      setCommunes([]);
      const _perimetreElus = await elusService.getPersonnesByPerimetre(perimetre);
      const _communes = await communesService.getByPerimetre(perimetre.id);

      const _elus: Array<Personne> = elusUtil.filterAndBoilElusMandatsDown(
        _perimetreElus,
        (m: Mandat): boolean => {
          return (
            [
              Fonctions.PRESIDENT_COMMISSION_LOCALE,
              Fonctions.SUPPLEANT_PRESIDENT_COMMISSION_LOCALE,
              Fonctions.DELEGUE_ASSEMBLEE_GENERALE,
            ].includes(m.idFonction as Fonctions) &&
            m.idPerimetre === perimetre.id &&
            m.competence === perimetre.competence
          );
        }
      );

      _elus.sort(elusUtil.sortElusByMandatImportance);
      const activeElus = elusUtil.getActiveElusAndMandats(_elus);

      const _tableElusMandatCommune: Array<PerimetreDelegueRow> = activeElus.map((e, i) => {
        return {
          elu: { id: e.id, nom: e.nom, prenom: e.prenom, dateNaissance: e.dateNaissance },
          eluName: `${e.nom} ${e.prenom}`,
          mandatsWeight: i,
          mandats: e.mandats,
          communeName:
            _communes?.find(
              (c) => c.id === e.mandats?.map((m) => m.codeCollectivite).filter((f) => f != null)[0]
            )?.libelle ?? "",
          commune: _communes?.find(
            (c) => c.id === e.mandats?.map((m) => m.codeCollectivite).filter((f) => f != null)[0]
          ),
        } satisfies PerimetreDelegueRow;
      });

      setTableElusMandatCommune(_tableElusMandatCommune);
      setElus(activeElus);
      setCommunes(_communes);
    }

    void catchDatasErrors(getElus);
  }, [catchDatasErrors, perimetre]);

  function createFeuillePresence(): void {
    async function create(): Promise<void> {
      await excelUtil.createFeuillePresencePerimetre(perimetre, elus, communes);
    }

    void catchExcelErrors(create);
  }

  if (isDatasLoading) {
    return <PerimetreDeleguesSdeaSkeleton />;
  }

  if (tableSort.length === 0) {
    return (
      <Grid item xs={12}>
        <Typography>Ce périmètre SDEA n'a aucun délégué.</Typography>
      </Grid>
    );
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <Typography component="span" fontWeight="600">
            {tableSort.length}
          </Typography>{" "}
          membre{tableSort.length > 1 ? "s" : ""} du périmètre SDEA :
        </Typography>
      </Grid>
      <Authorization roles={[ApplicationRole.REPORTS_READ_ALL]}>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            onClick={createFeuillePresence}
            loading={isExcelLoading}>
            EXPORTER FEUILLE DE PRESENCE
          </LoadingButton>
        </Grid>
      </Authorization>
      <Grid item xs={12}>
        <TableContainer
          sx={{
            [`&.${tableContainerClasses.root} .${tableBodyClasses.root} .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]:
              { border: "none" },
          }}>
          <Table>
            <PerimetreDeleguesSdeaHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {tableSort?.map((row: PerimetreDelegueRow) => (
                <PerimetreDeleguesSdeaRow
                  key={row.elu.id}
                  importantFonctions={[Fonctions.PRESIDENT_COMMISSION_LOCALE]}
                  data={row}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

const PerimetreDeleguesSdeaWithErrorBoundary = withComponentErrorBoundary(PerimetreDeleguesSdea);
export default PerimetreDeleguesSdeaWithErrorBoundary;
