import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";
import type { SxProps } from "@mui/material";
import { elusUtil } from "utils";
import type { SdeappsError } from "utils/errorHandling";
import type { Mandat, Personne } from "models";
import CommuneLink from "components/Links/CommuneLink";
import PerimetreLinkWithErrorBoundary from "components/Links/PerimetreLink";
import TerritoireLink from "components/Links/TerritoireLink";
import CollectiviteLinkInstitutionnelMandat from "components/Informations/Elus/CollectiviteLinkInstitutionnelMandat";
import InlineErrorChip from "components/Errors/InlineErrorChip";
import TypographySkeleton from "components/Links/TypographySkeleton";

interface SdeaMandatCollectiviteLinkProps {
  mandatSdea: Mandat;
  noLink: boolean;
}

function SdeaMandatCollectiviteLink({
  mandatSdea,
  noLink,
}: Readonly<SdeaMandatCollectiviteLinkProps>): ReactNode {
  if (mandatSdea.codeCollectivite != null) {
    return (
      <CommuneLink
        id={mandatSdea.codeCollectivite}
        libelle={mandatSdea.libelleCollectivite}
        noLink={noLink}
      />
    );
  } else if (mandatSdea.idPerimetre != null && mandatSdea.competence != null) {
    return (
      <PerimetreLinkWithErrorBoundary
        id={mandatSdea.idPerimetre}
        libelle={mandatSdea.libellePerimetre}
        noLink={noLink}
      />
    );
  } else if (mandatSdea.idTerritoire != null) {
    return (
      <TerritoireLink
        id={mandatSdea.idTerritoire}
        libelle={mandatSdea.libelleTerritoire}
        noLink={noLink}
      />
    );
  } else return null;
}

interface HigherMandatsSubtitleProps {
  elu?: Personne;
  sx?: SxProps;
  isLoading?: boolean;
  error?: SdeappsError;
  noLink?: boolean;
}

function HigherMandatsSubtitle({
  elu,
  sx,
  isLoading = false,
  error,
  noLink = false,
}: Readonly<HigherMandatsSubtitleProps>): ReactNode {
  const [higherSdeaMandat, setHigherSdeaMandat] = useState<Mandat>();
  const [higherInstitutionnelMandat, setHigherInstitutionnelMandat] = useState<Mandat>();

  useEffect(() => {
    setHigherSdeaMandat(undefined);
    setHigherInstitutionnelMandat(undefined);
    if (elu != null) {
      const activeElus = elusUtil.getActiveElusAndMandats([elu]);

      if (activeElus.length > 0) {
        setHigherSdeaMandat(elusUtil.getHigherSdeaMandat(activeElus[0]));
        setHigherInstitutionnelMandat(elusUtil.getHigherInstitutionnelMandat(activeElus[0]));
      }
    }
  }, [elu]);

  if (error != null) {
    return <InlineErrorChip />;
  }

  if (isLoading || elu == null) {
    return <TypographySkeleton width="100%" />;
  }

  if (higherSdeaMandat == null && higherInstitutionnelMandat == null) return null;

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        backgroundColor: "info.light",
        padding: 1,
        borderRadius: 1,
        ...sx,
      }}
      gap={1}>
      {higherSdeaMandat != null && (
        <Grid item xs={12}>
          <Typography>
            {elusUtil.getLibelleFonction(higherSdeaMandat)}
            {higherSdeaMandat.codeCollectivite != null && (
              <>
                {" - "}
                <SdeaMandatCollectiviteLink mandatSdea={higherSdeaMandat} noLink={noLink} />
              </>
            )}
          </Typography>
        </Grid>
      )}
      {higherInstitutionnelMandat != null && (
        <Grid item xs={12}>
          <Typography>
            {elusUtil.getLibelleFonction(higherInstitutionnelMandat)} {" - "}
            <CollectiviteLinkInstitutionnelMandat
              mandatInstitutionnel={higherInstitutionnelMandat}
              noLink={noLink}
            />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default HigherMandatsSubtitle;
