import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableBodyClasses,
  tableCellClasses,
  tableContainerClasses,
  tableRowClasses,
} from "@mui/material";
import { type ReactElement } from "react";

function PerimetreDeleguesSdeaSkeleton(): ReactElement {
  return (
    <Grid item container xs={12}>
      <TableContainer
        sx={{
          [`&.${tableContainerClasses.root} .${tableBodyClasses.root} .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]:
            { border: "none" },
        }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight={600}>Élu</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Fonctions</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Commune</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[0, 1, 2, 3, 4, 5].map((i) => (
              <TableRow key={i}>
                {[10, 11, 12].map((j) => (
                  <TableCell key={j}>
                    <Typography>
                      <Skeleton />
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default PerimetreDeleguesSdeaSkeleton;
