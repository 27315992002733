import type { ReactNode } from "react";
import {
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import type { EtablissementPublic } from "models";
import TelephoneSpan from "components/Span/TelephoneSpan";
import AdresseSpan from "components/Span/AdresseSpan";
import type { SdeappsError } from "utils/errorHandling";

interface EluContactInformationsPubliquesProps {
  etablissement: EtablissementPublic | undefined;
  isLoading: boolean;
  etablissementError: SdeappsError | undefined;
}

function EluContactInformationsPubliques({
  etablissement,
  isLoading,
  etablissementError,
}: Readonly<EluContactInformationsPubliquesProps>): ReactNode {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} md={6} lg={4}>
        <List>
          <ListItem>
            <ListItemIcon>
              <CallOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight="bold">Téléphone</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <TelephoneSpan
                telephone={etablissement?.telephone}
                label="Professionnel"
                isLoading={isLoading}
                error={etablissementError != null}
                hasLink
              />
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <List>
          <ListItem>
            <ListItemIcon>
              <EmailOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight="bold">Adresse</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <AdresseSpan
                adresse={etablissement?.adresse}
                nom={etablissement?.nom}
                isLoading={isLoading}
                error={etablissementError != null}
              />
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <List>
          <ListItem>
            <ListItemIcon>
              <EmailOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight="bold">E-mail Mairie</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              {isLoading && <Skeleton />}
              <Link href={"mailto:" + etablissement?.email}>{etablissement?.email}</Link>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}

export default EluContactInformationsPubliques;
