import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { communesService, communesAssocieesDelegueesService, transfertsService } from "services";
import { Container, Grid } from "@mui/material";
import type { Commune, Transfert, CommuneAssocieeDeleguee } from "models";
import LoadingScreen from "components/Template/LoadingScreen";
import PageTitle from "components/PageTitle";
import InformationBox from "components/InformationBox";
import PersonIcon from "@mui/icons-material/Person";
import CommuneElusInformationsWithErrorBoundary from "./components/CommuneElusInformationsWithErrorBoundary";
import { SdeaIcon, CommuneIcon, CollectiviteIcon } from "icons";
import { competencesUtil } from "utils";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import PerimetresInformationsWithErrorBoundary from "components/PerimetresInformationsWithErrorBoundary";
import CommuneEtablissementPublicInformations from "./components/CommuneEtablissementPublicInformations";
import CommuneEpcisInformationsWithErrorBoundary from "./components/CommuneEpcisInformationsWithErrorBoundary";
import CommunePorteesInformationBoxWithErrorBoundary from "./components/Transferts/CommunePorteesInformationBox";
import type Competence from "constants/Competence";
import CommuneLieesSubtitle from "components/CommuneLieesSubtitle";
import EtablissementPublicInformationBox from "components/Informations/EtablissementPublic/EtablissementPublicInformationBox";
import CollectiviteNonAdherenteSubtitle from "components/CollectiviteNonAdherenteSubtitle";
import InputSkeleton from "components/InputSkeleton";
import { useRequiredParams } from "hooks";

function CommunePage(): ReactNode {
  const { id } = useRequiredParams<{ id: string }>();
  const [commune, setCommune] = useState<Commune>();
  const [isCommuneAdherente, setIsCommuneAdherente] = useState(false);
  const [transferts, setTransferts] = useState<Array<Transfert>>([]);
  const [competences, setCompetences] = useState<Array<Competence>>([]);
  const [communesAssDeg, setCommunesAssDeg] = useState<Array<CommuneAssocieeDeleguee>>([]);
  const [open, setOpen] = useState(false);

  const { catchErrors } = useErrorHandler();
  const {
    error: transfertsError,
    catchErrors: catchTransfertsError,
    isLoading: isTransfertsLoading,
  } = useErrorHandler(true);
  const {
    error: assDegError,
    catchErrors: catchAssDegError,
    isLoading: isAssDegLoading,
  } = useErrorHandler(true);

  useEffect(() => {
    async function getCommune(): Promise<void> {
      setCommune(undefined);
      const _commune = await communesService.getById(id);
      document.title = _commune.libelle;

      setCommune(_commune);
    }

    async function getTransferts(): Promise<void> {
      setCompetences([]);
      setTransferts([]);
      setIsCommuneAdherente(false);
      const _transferts = await transfertsService.getByCommune(id);
      _transferts.sort(competencesUtil.sortTransfertByCompetence);

      setTransferts(_transferts);
      setCompetences(competencesUtil.getCompetencesFromTransferts(_transferts));
      setIsCommuneAdherente(_transferts?.length > 0);
    }

    async function getCommunesAssDeg(): Promise<void> {
      setCommunesAssDeg([]);
      const _communesAssDeg = await communesAssocieesDelegueesService.getByChefLieuId(id);

      setCommunesAssDeg(_communesAssDeg);
    }

    void catchErrors(getCommune);
    void catchTransfertsError(getTransferts);
    void catchAssDegError(getCommunesAssDeg);
  }, [catchErrors, catchTransfertsError, catchAssDegError, id]);

  function handleClickOpen(): void {
    setOpen(true);
  }

  if (commune == null) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <PageTitle
          title={commune.libelle}
          icon={<CommuneIcon />}
          subtitle="Commune"
          competences={competences}
        />

        <CommuneLieesSubtitle
          communesAssocieesDeleguees={communesAssDeg}
          isLoading={isAssDegLoading}
          error={assDegError}
        />

        {isTransfertsLoading ? (
          <InputSkeleton />
        ) : (
          !isCommuneAdherente && (
            <Grid container item xs={12}>
              <Grid item xs="auto">
                <CollectiviteNonAdherenteSubtitle />
              </Grid>
            </Grid>
          )
        )}

        <Grid item container xs={12} lg={9}>
          <EtablissementPublicInformationBox
            openModal={handleClickOpen}
            additionalRightStuff={`Code INSEE : ${commune.id}`}>
            <CommuneEtablissementPublicInformations
              commune={commune}
              open={open}
              setOpen={setOpen}
            />
          </EtablissementPublicInformationBox>
        </Grid>
        <Grid item container xs={12} lg={3}>
          <InformationBox hasBackground icon={PersonIcon} articleTitle="Élus">
            <CommuneElusInformationsWithErrorBoundary commune={commune} />
          </InformationBox>
        </Grid>
        <Grid item container xs={12} md={isCommuneAdherente ? 6 : 12}>
          <InformationBox hasBackground icon={CollectiviteIcon} articleTitle="EPCI">
            <CommuneEpcisInformationsWithErrorBoundary idCommune={id} />
          </InformationBox>
        </Grid>

        {isCommuneAdherente && (
          <Grid item container xs={12} md={6}>
            <InformationBox hasBackground icon={SdeaIcon} articleTitle="Périmètres SDEA">
              <PerimetresInformationsWithErrorBoundary
                transferts={transferts}
                error={transfertsError}
                pointOfView="commune"
              />
            </InformationBox>
          </Grid>
        )}

        {isCommuneAdherente && (
          <Grid item container xs={12}>
            <CommunePorteesInformationBoxWithErrorBoundary
              transferts={transferts}
              isLoading={isTransfertsLoading}
              error={transfertsError}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

const CommunePageWithErrorBoundary = withPageErrorBoundary(CommunePage);

export default CommunePageWithErrorBoundary;
