import { useCallback, useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Grid, Typography } from "@mui/material";
import type { TypographyOwnProps } from "@mui/material";
import type { Commune, CommuneAssocieeDeleguee } from "models";
import CommuneLink from "./Links/CommuneLink";
import { arrayUtil } from "@sdeapps/react-core";
import { communesAssocieesDelegueesService } from "services";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import type { SdeappsError } from "utils/errorHandling";
import ListSkeleton from "./ListSkeleton";

interface CommunesListProps {
  communes: Array<Commune>;
  communesAssocieesDelegueesToHighlight?: Set<string>;
  columnNumber?: number;
  lengthSentence?: string;
  zeroSentence?: string;
  isLoading?: boolean;
  error?: SdeappsError;
}

function CommunesList({
  communes,
  communesAssocieesDelegueesToHighlight,
  columnNumber = 3,
  lengthSentence = `${communes.length} communes ont adhéré.`,
  zeroSentence = "Aucune commune n'a adhéré",
  isLoading = false,
  error,
}: Readonly<CommunesListProps>): ReactElement {
  const [communesColumns, setCommunesColumns] = useState<Array<Array<Commune>>>([]);
  const [allCads, setAllCads] = useState<Array<CommuneAssocieeDeleguee>>([]);

  const { catchErrors, setOriginalError, isLoading: isCadsLoading } = useErrorHandler();

  useEffect(() => {
    if (error != null) {
      setOriginalError(error);
    }
  }, [error, setOriginalError]);

  useEffect(() => {
    const _communes = [...communes];
    _communes.sort((a, b) => a.libelle.localeCompare(b.libelle));
    setCommunesColumns(arrayUtil.separateIntoNArrays(_communes, columnNumber));
  }, [communes, columnNumber]);

  useEffect(() => {
    async function getCommunesAssocieesDeleguees(): Promise<void> {
      const allCADs = await communesAssocieesDelegueesService.getAll();
      setAllCads(allCADs);
    }

    void catchErrors(getCommunesAssocieesDeleguees);
  }, [catchErrors]);

  const getStyleToHighlight = useCallback(
    (communeId: string): { fontWeight: string | number; color: TypographyOwnProps["color"] } => {
      if (communesAssocieesDelegueesToHighlight?.has(communeId) === true) {
        return { fontWeight: 600, color: "primary.main" };
      } else {
        return { fontWeight: "inherit", color: "inherit" };
      }
    },
    [communesAssocieesDelegueesToHighlight]
  );

  if (isLoading || isCadsLoading) {
    return <ListSkeleton lines={3} />;
  }

  if (communes.length === 0) {
    return (
      <Grid item xs={12}>
        <Typography>{zeroSentence}</Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="caption" fontStyle="italic">
          {lengthSentence}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        {communesColumns.map((communeColumn, i) => {
          const key = `column ${i}`;
          return (
            <Grid
              container
              item
              xs={12}
              md={12 / columnNumber}
              direction="column"
              key={key}
              spacing={0.5}>
              {communeColumn.map((commune) => {
                return (
                  <Grid item container key={commune.siren}>
                    <Grid item xs={12}>
                      <CommuneLink commune={commune} />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: -0.5 }}>
                      <Typography variant="caption" fontStyle="italic" lineHeight={2}>
                        {allCads
                          .filter((cad) => cad.codeChefLieu === commune.id)
                          .map((cad, i, a) => (
                            <span key={cad.id}>
                              <CommuneLink
                                commune={cad}
                                variant="caption"
                                {...getStyleToHighlight(cad.id)}
                              />
                              {i < a.length - 1 && ", "}
                            </span>
                          ))}
                        {"\u00A0"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

const CommunesListWithErrorBoundary = withComponentErrorBoundary(CommunesList);

export default CommunesListWithErrorBoundary;
