import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Container, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import LoadingScreen from "components/Template/LoadingScreen";
import type { Territoire, Personne, Perimetre } from "models";
import { territoiresService, elusService, perimetresService } from "services";
import { elusUtil, competencesUtil } from "utils";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import TerritoirePerimetresSdeaInformations from "./components/TerritoirePerimetresSdeaInformations";
import TerritoireInformationsGenerales from "./components/TerritoireInformationsGenerales";
import InformationBox from "components/InformationBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Fonctions from "constants/Fonctions";
import FilteredElusList from "components/Informations/Elus/FilteredElusList";
import { SdeaIcon } from "icons";
import PersonIcon from "@mui/icons-material/Person";
import TerritoireInformationsSdeaWithErrorBoundary from "./components/TerritoireInformationsSdea";
import type Competence from "constants/Competence";
import { useRequiredParams } from "hooks";

function TerritoirePage(): ReactNode {
  const { id } = useRequiredParams<{ id: string }>();
  const [territoire, setTerritoire] = useState<Territoire>();
  const [perimetres, setPerimetres] = useState<Array<Perimetre>>([]);
  const [elusTerritoire, setElusTerritoire] = useState<Array<Personne>>([]);
  const [competences, setCompetences] = useState<Array<Competence>>([]);

  const { catchErrors } = useErrorHandler();
  const {
    error: elusError,
    catchErrors: catchElusErrors,
    isLoading: isElusLoading,
  } = useErrorHandler();

  useEffect(() => {
    async function getElusTerritoire(): Promise<void> {
      setElusTerritoire([]);
      const _territoireElus = await elusService.getPersonnesByTerritoire(id);

      const _activeTerritoireElus = elusUtil.getActiveElusAndMandats(_territoireElus);

      setElusTerritoire(_activeTerritoireElus);
    }

    async function getPerimetres(): Promise<void> {
      setPerimetres([]);
      setCompetences([]);

      const _perimetres = await perimetresService.getByTerritoire(id);
      _perimetres
        .sort((p1, p2) => p1.libelle.localeCompare(p2.libelle))
        .sort(competencesUtil.sortPerimetresByCompetence);

      setPerimetres(_perimetres);
      setCompetences(competencesUtil.getCompetencesFromPerimetres(_perimetres));
    }

    async function getTerritoire(): Promise<void> {
      void getPerimetres();
      setTerritoire(undefined);
      const _territoire = await territoiresService.getById(id);
      document.title = _territoire.libelle;

      setTerritoire(_territoire);
    }

    void catchErrors(getTerritoire);

    void catchElusErrors(getElusTerritoire);
  }, [catchElusErrors, catchErrors, id]);

  if (territoire == null) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <PageTitle
          title={territoire.libelle}
          icon={<SdeaIcon />}
          subtitle="Territoire"
          competences={competences}
        />
        <Grid item container xs={12} md={4}>
          <InformationBox icon={VisibilityIcon} articleTitle="Informations Générales" hasBackground>
            <TerritoireInformationsGenerales
              territoire={territoire}
              elus={elusTerritoire}
              isLoading={isElusLoading}
            />
          </InformationBox>
        </Grid>
        <Grid item container xs={12} md={4}>
          <InformationBox icon={SdeaIcon} articleTitle="Informations SDEA" hasBackground>
            <TerritoireInformationsSdeaWithErrorBoundary
              territoire={territoire}
              perimetres={perimetres}
            />
          </InformationBox>
        </Grid>
        <Grid item container xs={12} md={4}>
          <InformationBox icon={PersonIcon} articleTitle="Commission permanente" hasBackground>
            <FilteredElusList
              elus={elusTerritoire}
              fonctions={Fonctions.COMMISSION_PERMANENTE}
              codeTerritoire={territoire.id}
              isLoading={isElusLoading}
              error={elusError}
            />
          </InformationBox>
        </Grid>
        <Grid item container xs={12} md={6}>
          <InformationBox icon={PersonIcon} articleTitle="Conseil d'administration" hasBackground>
            <FilteredElusList
              elus={elusTerritoire}
              fonctions={Fonctions.CONSEIL_ADMINISTRATION}
              codeTerritoire={territoire.id}
              isLoading={isElusLoading}
              error={elusError}
            />
          </InformationBox>
        </Grid>
        <Grid item container xs={12} md={6}>
          <InformationBox icon={PersonIcon} articleTitle="Conseil territorial" hasBackground>
            <FilteredElusList
              elus={elusTerritoire}
              fonctions={Fonctions.CONSEIL_TERRITORIAL}
              codeTerritoire={territoire.id}
              isLoading={isElusLoading}
              error={elusError}
            />
          </InformationBox>
        </Grid>
        <Grid item container xs={12}>
          <TerritoirePerimetresSdeaInformations perimetres={perimetres} />
        </Grid>
      </Grid>
    </Container>
  );
}

const TerritoirePageWithErrorBoundary = withPageErrorBoundary(TerritoirePage);

export default TerritoirePageWithErrorBoundary;
