import { Container, Grid, Box, Typography } from "@mui/material";
import InformationBox from "components/InformationBox";
import PageTitle from "components/PageTitle";
import { CollectiviteIcon } from "icons";
import type { ReactNode } from "react";
import { epcisUtil } from "utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import type { Epci } from "models";

interface EpciDissousPageProps {
  epci: Epci;
}

function EpciDissousPage({ epci }: Readonly<EpciDissousPageProps>): ReactNode {
  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <PageTitle
          title={epcisUtil.ToFullName(epci.libelle)}
          icon={<CollectiviteIcon />}
          subtitle="EPCI"
        />

        <Grid item container xs={12}>
          <Box sx={{ backgroundColor: "error.light", px: 2, py: 1, borderRadius: "5px" }}>
            <Typography component="span" color="error.dark" fontWeight="600">
              Cet EPCI est dissous
            </Typography>
          </Box>
        </Grid>

        <Grid item container xs={12}>
          <InformationBox hasBackground icon={VisibilityIcon} articleTitle="Informations générales">
            <Grid container item xs={12}>
              <Grid container item xs={12}>
                <Typography>SIREN : {epci.id}</Typography>
              </Grid>
            </Grid>
          </InformationBox>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EpciDissousPage;
