import type { ReactNode } from "react";
import { Grid } from "@mui/material";
import type { Transfert, Commune } from "models";
import { usePerimetreTransfertsGrouping } from "../../hooks/usePerimetreTransfertsGrouping";
import EauAssGepuSousCompetencesAccordion from "components/Informations/SousCompetences/EauAssGepuSousCompetencesAccordion";
import PerimetrePorteesAccordionSummary from "./PerimetrePorteesAccordionSummary";
import { useDisplayDates } from "hooks";

interface PerimetreEauAssGepuSousCompetencesProps {
  communes: Array<Commune>;
  transferts: Array<Transfert>;
}

function PerimetreEauAssGepuSousCompetences({
  communes,
  transferts,
}: Readonly<PerimetreEauAssGepuSousCompetencesProps>): ReactNode {
  const { isDisplayingDates } = useDisplayDates();
  const currentGroupedTransferts = usePerimetreTransfertsGrouping(transferts, isDisplayingDates);

  return (
    <Grid container item xs={12} spacing={2}>
      {currentGroupedTransferts.map((transfertsByCommune) => {
        return (
          <EauAssGepuSousCompetencesAccordion
            key={`${transfertsByCommune[0]?.[0]?.idCommune}${transfertsByCommune[0]?.[0]?.idEpci}`}
            defaultExpanded={currentGroupedTransferts.length === 1}
            transferts={transfertsByCommune[0]}
            summary={
              <PerimetrePorteesAccordionSummary
                communes={communes.filter((c) =>
                  transfertsByCommune.some((gT) => gT[0].idCommune === c.id)
                )}
                idEpci={transfertsByCommune[0]?.[0]?.idEpci}
              />
            }
          />
        );
      })}
    </Grid>
  );
}

export default PerimetreEauAssGepuSousCompetences;
