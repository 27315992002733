import type { ReactNode } from "react";
import { Button, Collapse, Container, FormControlLabel, Grid, Switch } from "@mui/material";
import PageTitle from "components/PageTitle";
import { useEffect, useState } from "react";
import LoadingScreen from "components/Template/LoadingScreen";
import type { Personne } from "models";
import InformationBox from "components/InformationBox";
import { SdeaIcon, CommuneIcon } from "icons";
import MandatsInformationsList from "./components/MandatsInformationsList";
import { elusService } from "services";
import { elusUtil } from "utils";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import HigherMandatsSubtitle from "./components/HigherMandatsSubtitle";
import EluContactInformationsWithErrorBoundary from "./components/EluContactInformationsWithErrorBoundary";
import PersonIcon from "@mui/icons-material/Person";
import { Authorization } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";
import AddIcon from "@mui/icons-material/Add";
import { useRequiredParams } from "hooks";

function getCivilite(sexe: string): string {
  return sexe === "F" ? "Mme" : "M.";
}

function EluPage(): ReactNode {
  const { id } = useRequiredParams<{ id: string }>();
  const [elu, setElu] = useState<Personne>();
  const [isModifying, setIsModifying] = useState(false);
  const [isDisplayingInstitutionnelHistorique, setIsDisplayingInstitutionnelHistorique] =
    useState(false);
  const [isDisplayingSDEAHistorique, setIsDisplayingSDEAHistorique] = useState(false);
  const [isCreatingNewSdeaMandat, setIsCreatingNewSdeaMandat] = useState(false);

  const { catchErrors } = useErrorHandler();

  useEffect(() => {
    async function getElu(): Promise<void> {
      setElu(undefined);
      const _elu = await elusService.getPersonneById(id);
      document.title = `${_elu.nom} ${_elu.prenom}`;

      setElu(_elu);
      setIsDisplayingInstitutionnelHistorique(false);
      setIsDisplayingSDEAHistorique(false);
    }

    // Au changement de page Elu X vers Elu Y on reset la modification
    setIsModifying(false);
    setIsCreatingNewSdeaMandat(false);
    void catchErrors(getElu);
  }, [catchErrors, id]);

  if (elu == null) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <PageTitle
          title={`${elu.sexe != null ? getCivilite(elu.sexe) + " " : ""}${elu.nom} ${elu.prenom}`}
          icon={<PersonIcon />}
          subtitle="Élu"
        />
        <Grid item container xs={12}>
          <HigherMandatsSubtitle elu={elu} />
        </Grid>
        <Grid item container xs={12}>
          <InformationBox hasBackground icon={CommuneIcon} articleTitle="Contacts">
            <EluContactInformationsWithErrorBoundary
              elu={elu}
              isModifying={isModifying}
              setIsModifying={setIsModifying}
            />
          </InformationBox>
        </Grid>
        <Grid item container xs={12}>
          <InformationBox
            hasBackground
            icon={CommuneIcon}
            articleTitle="Fonctions Institutionnelles">
            <Grid item xs={12}>
              <MandatsInformationsList
                mandats={elu.mandats?.filter(elusUtil.filterActiveMandatsInstitutionnels)}
                typeMandats="institutionnels"
                noMandatsFallback="Cette personne n'a aucun mandat institutionnel actif"
              />
            </Grid>
            <Authorization roles={ApplicationRole.ADMINISTRATOR}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isDisplayingInstitutionnelHistorique}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        setIsDisplayingInstitutionnelHistorique(event.target.checked);
                      }}
                    />
                  }
                  label="Afficher l'historique"
                />
              </Grid>
              <Grid item xs={12}>
                <Collapse in={isDisplayingInstitutionnelHistorique}>
                  <MandatsInformationsList
                    mandats={elu.mandats?.filter(elusUtil.filterHistoricMandatsInstitutionnels)}
                    typeMandats="institutionnels"
                    noMandatsFallback="Cette personne n'a aucun mandat institutionnel historique"
                  />
                </Collapse>
              </Grid>
            </Authorization>
          </InformationBox>
        </Grid>
        <Grid item container xs={12}>
          <InformationBox hasBackground icon={SdeaIcon} articleTitle="Fonctions SDEA">
            <Grid item xs={12}>
              <MandatsInformationsList
                mandats={elu.mandats?.filter(elusUtil.filterActiveMandatsSdea)}
                typeMandats="SDEA"
                noMandatsFallback="Cette personne n'a aucun mandat SDEA actif"
                displayCreateNewMandat={isCreatingNewSdeaMandat}
                cancelDisplayCreateNewMandat={() => {
                  setIsCreatingNewSdeaMandat(false);
                }}
                createMandatEluId={elu.id}
              />
            </Grid>
            <Authorization roles={ApplicationRole.ADMINISTRATOR}>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isDisplayingSDEAHistorique}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                          setIsDisplayingSDEAHistorique(event.target.checked);
                        }}
                      />
                    }
                    label="Afficher l'historique"
                  />
                </Grid>
                {!isCreatingNewSdeaMandat && (
                  <Grid container item xs sm={6} justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setIsCreatingNewSdeaMandat(true);
                      }}
                      startIcon={<AddIcon />}>
                      Ajouter un mandat
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Collapse in={isDisplayingSDEAHistorique}>
                  <MandatsInformationsList
                    mandats={elu.mandats?.filter(elusUtil.filterHistoricMandatsSdea)}
                    typeMandats="SDEA"
                    noMandatsFallback="Cette personne n'a aucun mandat SDEA historique"
                  />
                </Collapse>
              </Grid>
            </Authorization>
          </InformationBox>
        </Grid>
      </Grid>
    </Container>
  );
}

const EluPageWithErrorBoundary = withPageErrorBoundary(EluPage);

export default EluPageWithErrorBoundary;
