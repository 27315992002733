import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import type { Commune, Mandat, Personne } from "models";
import type { ReactNode, MouseEvent } from "react";

export type Order = "asc" | "desc";

export interface ColumnKeyName {
  eluName: string;
  mandatsWeight: number;
  communeName: string;
}

export interface PerimetreDelegueRow extends ColumnKeyName {
  elu: Personne;
  mandats: Array<Mandat> | undefined;
  commune: Commune | undefined;
}

export interface HeadCell {
  id: keyof ColumnKeyName;
  label: string;
}

const headCells: Array<HeadCell> = [
  {
    id: "eluName",
    label: "Élu",
  },
  {
    id: "mandatsWeight",
    label: "Fonctions",
  },
  {
    id: "communeName",
    label: "Commune",
  },
];

interface PerimetreDeleguesSdeaHeaderProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof ColumnKeyName) => void;
  order: Order;
  orderBy: string;
}

function PerimetreDeleguesSdeaHeader({
  order,
  orderBy,
  onRequestSort,
}: Readonly<PerimetreDeleguesSdeaHeaderProps>): ReactNode {
  const createSortHandler =
    (property: keyof ColumnKeyName) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              <Typography fontWeight={600}>{headCell.label}</Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default PerimetreDeleguesSdeaHeader;
