import type { ReactElement } from "react";
import {
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import competenceMap from "constants/CompetenceMap";
import type { Perimetre } from "models";
import PerimetreLinkWithErrorBoundary from "components/Links/PerimetreLink";

interface PerimetreByCompetenceListProps {
  perimetres: Array<Perimetre> | null | undefined;
  label?: string;
}

function PerimetreByCompetenceList({
  perimetres,
  label,
}: Readonly<PerimetreByCompetenceListProps>): ReactElement {
  if (perimetres == null) {
    return (
      <Typography variant="caption" fontStyle="italic" m={2}>
        Il n'y a aucun périmetre pour la compétence {label}
      </Typography>
    );
  }
  return (
    <List
      sx={{ backgroundColor: "initial", width: "100%" }}
      subheader={
        <ListSubheader sx={{ backgroundColor: "initial" }}>
          <Typography variant="caption" fontStyle="italic">
            Il y a <strong>{perimetres.length}</strong>{" "}
            {perimetres.length > 1 ? "périmètres" : "périmètre"}
            {label != null && ` pour la compétence ${label}`}
          </Typography>
        </ListSubheader>
      }>
      {perimetres.map((p) => {
        const CompIcon = competenceMap.get(p.competence)?.icon;
        return (
          <ListItem key={`${p.competence}${p.id}`}>
            {CompIcon != null && (
              <ListItemIcon>
                <CompIcon />
              </ListItemIcon>
            )}
            <ListItemText>
              <PerimetreLinkWithErrorBoundary
                perimetre={p}
                sx={{ color: `${competenceMap.get(p.competence)?.color}.dark` }}
              />
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}

export default PerimetreByCompetenceList;
