import { useCallback, useState } from "react";
import type { ReactElement } from "react";
import { Container, Grid, Typography } from "@mui/material";
import InformationBox from "components/InformationBox";
import PageTitle from "components/PageTitle";
import LoadingScreen from "components/Template/LoadingScreen";
import { PerimetreIcon } from "icons";
import { useNavigate } from "react-router-dom";
import { withPageErrorBoundary, useErrorHandler, SdeappsError } from "utils/errorHandling";
import TransfertEditionToolbar from "./components/TransfertEditionToolbar";
import PerimetreEditTransfertsList from "./components/PerimetreEditTransfertsList";
import {
  TransfertsEditionProvider,
  useTransfertsEditionContext,
} from "./store/transfertsEditionContext";
import useTransfertsEditionViewModelLoader from "./hooks/useTransfertsEditionViewModelLoader";
import TransfertEditionConfirmationDialog from "./components/TransfertEditionConfirmationDialog";
import { dateUtil } from "@sdeapps/react-core";
import ToastMessages from "constants/ToastMessages";
import { enqueueSnackbar } from "notistack";
import { transfertsService, networkService } from "services";
import { TransfertsEditionActionType } from "./store/transfertsEditionTypes";
import { routesConfig } from "config/app-config";
import type { SuppressionTransfertData } from "models";
import { useRequiredParams } from "hooks";

async function waitForTransfertViewReplication(
  idPerimetre: string,
  transfertIdsRemoved: Array<string>
): Promise<void> {
  const transferts = await transfertsService.getByPerimetre(idPerimetre);
  const isReplicationInProgress = transferts.some((transfert) =>
    transfertIdsRemoved.includes(transfert.id)
  );

  if (isReplicationInProgress) {
    throw new SdeappsError(
      `Les modification du périmètre ${idPerimetre} ne sont pas encore été répliquées`
    );
  }
}

function PerimetreEditTransferts(): ReactElement {
  const navigate = useNavigate();
  const { id } = useRequiredParams<{ id: string }>();
  useTransfertsEditionViewModelLoader(id);
  const {
    state: {
      perimetre,
      selectedTransferts,
      filteredTransferts,
      actionUtilisateur,
      actionParametres,
    },
  } = useTransfertsEditionContext();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const { catchErrors: catchApiModificationErrors, isLoading: isSendingApiModifications } =
    useErrorHandler({
      dontThrow: true,
      defaultIsLoading: false,
      default: () => {
        enqueueSnackbar({
          variant: "error",
          message: ToastMessages.ERROR_RETRY,
        });
      },
    });

  const getSuppressionTransfertData = useCallback(
    (idTransfert: string): SuppressionTransfertData => {
      return {
        idCommune: filteredTransferts.find((t) => t.id === idTransfert)?.idCommune ?? "",
        idTransfert,
      };
    },
    [filteredTransferts]
  );

  const callGestionApi = useCallback(() => {
    setIsConfirmationDialogOpen(false);
    const { idCible, dateEffet, raisonDuRetrait } = actionParametres;

    void catchApiModificationErrors(async () => {
      switch (actionUtilisateur) {
        case TransfertsEditionActionType.ReaffectationEPCI:
        case TransfertsEditionActionType.DirectIndirect:
        case TransfertsEditionActionType.IndirectDirect:
          await transfertsService.changeEpci({
            idEpci: idCible,
            dateEffet: dateUtil.format(dateEffet, "yyyy-MM-dd"),
            transfertIds: selectedTransferts,
          });
          break;
        case TransfertsEditionActionType.RetraitTransfert:
          if (raisonDuRetrait != null) {
            await transfertsService.retraitTransferts({
              raisonDuRetrait,
              dateEffet: dateUtil.format(dateEffet, "yyyy-MM-dd"),
              transfertIds: selectedTransferts,
            });
          } else {
            throw new SdeappsError("Une raison de retrait est nécessaire");
          }
          break;
        case TransfertsEditionActionType.SuppressionTransfert:
          await transfertsService.suppressionTransferts(
            selectedTransferts.map(getSuppressionTransfertData)
          );
          break;
        default:
          // Action non gérée ou traitée, pas d'appels affectués
          return;
      }

      await networkService.waitForReplication(
        async () => {
          await waitForTransfertViewReplication(id, selectedTransferts);
        },
        20,
        () => {
          navigate(routesConfig.perimetre.getParameterPath(id));
        },
        () => {
          enqueueSnackbar({
            autoHideDuration: 8000,
            variant: "warning",
            message: ToastMessages.LONG_REPLICATION,
          });
        }
      );
    });
  }, [
    actionParametres,
    id,
    catchApiModificationErrors,
    actionUtilisateur,
    selectedTransferts,
    getSuppressionTransfertData,
    navigate,
  ]);

  if (perimetre == null) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <PageTitle
          title={perimetre.libelle}
          icon={<PerimetreIcon />}
          subtitle="Périmètre : modification des transferts"
          competences={perimetre.competence}
        />
        <Grid item container xs={12}>
          <InformationBox hasBackground articleTitle="Modification des transferts">
            <TransfertEditionConfirmationDialog
              onConfirm={callGestionApi}
              onCancel={() => {
                setIsConfirmationDialogOpen(false);
              }}
              open={isConfirmationDialogOpen}
              isSending={isSendingApiModifications}
            />
            <TransfertEditionToolbar
              onSave={() => {
                setIsConfirmationDialogOpen(true);
              }}
              isSending={isSendingApiModifications}
            />
            {actionUtilisateur != null ? (
              <PerimetreEditTransfertsList />
            ) : (
              <Grid item xs={12}>
                <Typography textAlign="center">
                  Veuillez sélectionner une action pour afficher la liste de transferts éligibles
                </Typography>
              </Grid>
            )}
          </InformationBox>
        </Grid>
      </Grid>
    </Container>
  );
}

function PerimetreEditTransfertsWithContext(): ReactElement {
  return (
    <TransfertsEditionProvider>
      <PerimetreEditTransferts />
    </TransfertsEditionProvider>
  );
}

const PerimetreEditTransfertsWithErrorBoundary = withPageErrorBoundary(
  PerimetreEditTransfertsWithContext
);

export default PerimetreEditTransfertsWithErrorBoundary;
