import type { ReactNode } from "react";
import { useEffect } from "react";
import CompetencesEtPorteesInformationBox from "components/Informations/SousCompetences/CompetencesEtPorteesInformationBox";
import { withComponentErrorBoundary, useErrorHandler } from "utils/errorHandling";
import type { SdeappsError } from "utils/errorHandling";
import type { Transfert } from "models";
import CommunePorteesInformationsWithErrorBoundary from "./CommunePorteesInformationsWithErrorBoundary";
import LoadingScreen from "components/Template/LoadingScreen";
import { DisplayDatesProvider } from "providers";

interface CommunePorteesInformationBoxProps {
  transferts: Array<Transfert>;
  isLoading?: boolean;
  error?: SdeappsError;
}

function CommunePorteesInformationBox({
  transferts,
  isLoading = false,
  error,
}: Readonly<CommunePorteesInformationBoxProps>): ReactNode {
  const { setOriginalError } = useErrorHandler();
  useEffect(() => {
    if (error != null) {
      setOriginalError(error);
    }
  }, [error, setOriginalError]);

  return (
    <DisplayDatesProvider>
      <CompetencesEtPorteesInformationBox>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <CommunePorteesInformationsWithErrorBoundary transferts={transferts} />
        )}
      </CompetencesEtPorteesInformationBox>
    </DisplayDatesProvider>
  );
}

const CommunePorteesInformationBoxWithErrorBoundary = withComponentErrorBoundary(
  CommunePorteesInformationBox
);

export default CommunePorteesInformationBoxWithErrorBoundary;
